import {
    AuthenticationDetails,
    CognitoUser,
    CognitoUserPool
} from 'amazon-cognito-identity-js'
import axios from 'axios'
import PropTypes from 'prop-types'
import { createContext, useCallback, useEffect, useReducer } from 'react'
import accountReducer from 'store/accountReducer'
import { LOGIN, LOGOUT, NEW_PASSWORD_REQUIRED } from 'store/actions'
import { openSnackbar } from 'store/slices/snackbar'
import Loader from 'ui-component/Loader'

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    isRegistrationNeeded: false,
    user: null
}

const userPool = new CognitoUserPool({
    UserPoolId: process.env.REACT_APP_USER_POOL_ID || '',
    ClientId: process.env.REACT_APP_CLIENT_ID || ''
})
// ==============================|| AWS Cognito CONTEXT & PROVIDER ||============================== //
const AWSCognitoContext = createContext(null)

export const AWSCognitoProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState)

    const getUserAttributes = useCallback((currentUser) => {
        return new Promise((resolve, reject) => {
            if (!currentUser) {
                reject('No Cognito user provided')
                return
            }
            currentUser.getUserAttributes((error, attributes) => {
                if (error) {
                    console.warn(error)
                    reject(error)
                } else {
                    const results = {}
                    attributes?.forEach((attribute) => {
                        results[attribute.Name] = attribute.Value
                    })
                    resolve(results)
                }
            })
        })
    }, [])

    const refreshAxiosToken = async (error) => {
        const originalRequestConfig = error.config
        if (
            error.response &&
            error.response.status === 401 &&
            !originalRequestConfig._retry
        ) {
            originalRequestConfig._retry = true
            const token = await getNewAccessToken()
            axios.defaults.headers.common.Authorization = `Bearer ${token}`
            return axios({
                ...originalRequestConfig,
                headers: {
                    ...originalRequestConfig.headers,
                    Authorization: `Bearer ${token}`
                }
            })
        }
        return Promise.reject(error)
    }

    const getNewAccessToken = () => {
        return new Promise((resolve, reject) => {
            const cognitoUser = userPool.getCurrentUser()
            if (cognitoUser) {
                return cognitoUser.getSession(async (error, session) => {
                    if (error) {
                        reject(error)
                    }
                    return getUserAttributes(cognitoUser)
                        .then((attributes) => {
                            const token = session
                                ?.getAccessToken()
                                .getJwtToken()

                            resolve(token)
                        })
                        .catch((err) => {
                            reject()
                        })
                })
            } else {
                reject()
            }
        })
    }

    const getSession = useCallback(
        () =>
            new Promise((resolve, reject) => {
                const cognitoUser = userPool.getCurrentUser()
                if (cognitoUser) {
                    cognitoUser.getSession(async (error, session) => {
                        if (error) {
                            reject(error)
                            console.error(error)
                        }
                        return getUserAttributes(cognitoUser)
                            .then((attributes) => {
                                const token = session
                                    ?.getAccessToken()
                                    .getJwtToken()

                                // use the token or Bearer depend on the wait BE handle, by default amplify API only need to token.
                                axios.defaults.headers.common.Authorization = `Bearer ${token}`
                                // axiosServices.defaults.headers.common.Authorization = `Bearer ${token}`
                                axios.interceptors.response.use(
                                    (response) => response,
                                    refreshAxiosToken
                                )
                                resolve({
                                    cognitoUser,
                                    session,
                                    headers: {
                                        Authorization: `Bearer ${token}`
                                    }
                                })
                                dispatch({
                                    type: LOGIN,
                                    payload: {
                                        isLoggedIn: true,
                                        user: attributes
                                    }
                                })
                            })
                            .catch((err) => {
                                dispatch({ type: LOGOUT })
                                reject(err)
                            })
                    })
                } else {
                    console.log("Logout because there's no cognito user")
                    dispatch({ type: LOGOUT })
                }
            }),
        [getUserAttributes]
    )

    const login = useCallback(
        (email, password) =>
            new Promise((resolve, reject) => {
                const userData = new CognitoUser({
                    Username: email,
                    Pool: userPool
                })

                const authDetails = new AuthenticationDetails({
                    Username: email,
                    Password: password
                })
                //  ??
                return userData.authenticateUser(authDetails, {
                    onSuccess: (result) => {
                        getSession()

                        resolve(result)
                    },
                    onFailure: (error) => {
                        reject(error)
                    },
                    newPasswordRequired: (
                        userAttributes,
                        requiredAttributes
                    ) => {
                        newPasswordRequired(userAttributes, requiredAttributes)
                    }
                })
            }),
        [getSession]
    )

    const register = useCallback(
        (email, password, firstName, lastName) =>
            new Promise((resolve, reject) => {
                const newAttributes = [
                    {
                        Name: 'email',
                        Value: email
                    },
                    {
                        Name: 'name',
                        Value: `${firstName} ${lastName}`
                    }
                ]
                userPool.signUp(
                    email,
                    password,
                    newAttributes,
                    [],
                    async (error) => {
                        if (error) {
                            reject(error)
                            console.error(error)
                            return
                        }

                        resolve(undefined)
                        dispatch(
                            openSnackbar({
                                open: true,
                                message:
                                    "Compte créé. Un administrateur doit tout d'abord vous activer le compte",
                                variant: 'alert',
                                alert: {
                                    color: 'success'
                                },
                                close: false
                            })
                        )
                        window.location.href = '/dashboard/login'
                    }
                )
            }),
        []
    )

    const newPasswordRequired = useCallback(
        async (userAttributes, requiredAttributes) => {
            dispatch({
                type: NEW_PASSWORD_REQUIRED,
                payload: {
                    isLoggedIn: true,
                    user: userAttributes
                }
            })
        },
        [getSession]
    )

    const initialize = useCallback(async () => {
        try {
            await getSession()
        } catch {
            dispatch({ type: LOGOUT })
        }
    }, [getSession])

    const logout = useCallback(() => {
        const cognitoUser = userPool.getCurrentUser()

        if (cognitoUser) {
            cognitoUser.signOut()
            dispatch({ type: LOGOUT })
        }
    }, [])

    const confirmPasswordAfterReset = (email, code, newPassword) => {
        const cognitoUser = new CognitoUser({
            Username: email,
            Pool: userPool
        })

        return cognitoUser.confirmPassword(code, newPassword, {
            onSuccess: function (result) {
                console.warn(result)
                return Promise.resolve()
            },
            onFailure: function (err) {
                console.warn(err)
                return Promise.reject(err)
            }
        })
    }

    const resetPassword = async (email) => {
        const cognitoUser = new CognitoUser({
            Username: email,
            Pool: userPool
        })
        return cognitoUser.forgotPassword({
            onSuccess: function (result) {
                console.warn(result)
                return Promise.resolve()
            },
            onFailure: function (err) {
                console.warn(err)
                return Promise.reject(err)
            },
            inputVerificationCode(data) {
                return Promise.resolve()
            }
        })
    }

    const changePassword = (oldPassword, newPassword) => {
        const cognitoUser = userPool.getCurrentUser()
        return new Promise((resolve, reject) => {
            return cognitoUser.getSession((err, session) => {
                return cognitoUser.changePassword(
                    oldPassword,
                    newPassword,
                    (err, result) => {
                        if (err) {
                            reject(err)
                        }
                        resolve(result)
                    }
                )
            })
        })
    }

    const getUserLetters = () => {
        if (state.user && state.user.name) {
            return `${state.user.name.toUpperCase().substring(0, 2)}`
        }
        return ''
    }

    useEffect(() => {
        initialize()
    }, [initialize])

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />
    }

    return (
        <AWSCognitoContext.Provider
            value={{
                ...state,
                login,
                logout,
                register,
                resetPassword,
                confirmPasswordAfterReset,
                getUserLetters,
                changePassword
            }}
        >
            {children}
        </AWSCognitoContext.Provider>
    )
}

AWSCognitoProvider.propTypes = {
    children: PropTypes.node
}

export default AWSCognitoContext
