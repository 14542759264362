import { openSnackbar } from 'store/slices/snackbar'

export const getErrorCode = (err) => {
    if (err.response && err.response.data && err.response.data.message) {
        return err.response.data.message
    }
    return 'Exceptions::default'
}

const ERRORS = {
    'Exceptions::default':
        "La requête n'a pas pu aboutir, une erreur inconnue s'est produite.",
    Conflict: 'Cet élément existe déjà, merci de la modifier',
    PageNotEmpty: "La page n'est pas vide ...",
    OnePageMandatory: 'Le sondage doit avoir au moins une page...',
    SurveyNotReady:
        "Le sondage n'est pas prêt à être publié ! (Au moins 1 collecteur et 1 page)",
    OutOfIndexQuestionError:
        'Impossible de décaler la question (hors index) ...',
    OutOfIndexPageError: 'Impossible de décaler la page (hors index) ...',
    InvalidResponseTextQuestion: 'Réponse invalide pour une question textuelle',
    InvalidResponseChoiceQuestion: 'Réponse invalide pour une question à choix',
    InvalidQuestion: "La question n'est pas valide, les choix doivent être uniques et le titre ne doit pas être vide.",
    SurveyClosed: 'Le sondage est fermé !',
    SurveyDraft: 'Le sondage est encore en préparation !',
    QuestionTypeCannotBeChangedAfterResponse:
        "Le type de question ne peut pas être modifié après qu'une réponse ait été donnée !",
    UsernameExistsException: 'Cet email existe déjà !',
    TooManyRequestsException: 'Trop de tentatives de connexion !',
    InvalidPasswordException: 'Mot de passe invalide !',
    InternalErrorException: 'Erreur interne',
    CodeDeliveryFailureException:
        "Erreur lors de l'envoi du code de confirmation",
    TooManyCollectors: 'Trop de collecteurs pour ce sondage !',
    WrongAuthenticationToken: "Erreur lors de l'etablissement de la session",
}

export const openSnackbarWithError = (error, dispatch) => {
    const errorCode = getErrorCode(error)

    dispatch(
        openSnackbar({
            open: true,
            message: ERRORS[errorCode] || ERRORS['Exceptions::default'],
            variant: 'alert',
            alert: {
                color: 'error'
            },
            close: false
        })
    )
}
