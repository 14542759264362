import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { REGISTER_PATH, LOGIN_PATH } from 'config';
// project imports
import useAuth from 'hooks/useAuth';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
    const { isLoggedIn, isRegistrationNeeded } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoggedIn) {
            if (isRegistrationNeeded) {
                navigate(REGISTER_PATH, { replace: true });
            } else {
                navigate(LOGIN_PATH, { replace: true });
            }
            
        }
    }, [isLoggedIn, isRegistrationNeeded, navigate]);

    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
