import PropTypes from 'prop-types'
import { memo, useMemo } from 'react'

// material-ui
import {
    Box,
    Drawer,
    Link,
    Stack,
    Typography,
    useMediaQuery
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar'

// project imports
import { useDispatch, useSelector } from 'store'
import { drawerWidth } from 'store/constant'
import { openDrawer } from 'store/slices/menu'
import Chip from 'ui-component/extended/Chip'
import LogoSection from '../LogoSection'
import MenuCard from './MenuCard'
import MenuList from './MenuList'

const Sidebar = ({ window }) => {
    const theme = useTheme()
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))

    const dispatch = useDispatch()
    const { drawerOpen } = useSelector((state) => state.menu)

    const logo = useMemo(
        () => (
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
                    <LogoSection />
                </Box>
            </Box>
        ),
        []
    )

    const drawer = useMemo(
        () => (
            <PerfectScrollbar
                component="div"
                style={{
                    height: !matchUpMd
                        ? 'calc(100vh - 56px)'
                        : 'calc(100vh - 60px)',
                    paddingLeft: '16px',
                    paddingRight: '16px'
                }}
            >
                <MenuList />
                <MenuCard />

                <br />

                <Stack direction="row" justifyContent="center" sx={{ mb: 2 }}>
                    <Chip
                        label={`v${process.env.REACT_APP_VERSION}`}
                        disabled
                        chipcolor="primary"
                        size="small"
                        sx={{ cursor: 'pointer' }}
                    />
                </Stack>

                <br />

                <Link href="/legal" color="secondary">
                    <Typography variant="body2" color="secondary">
                        Mentions légales
                    </Typography>
                </Link>
                <Link href="/terms" color="secondary">
                    <Typography variant="body2" color="secondary">
                        Conditions Générales d'Utilisation
                    </Typography>
                </Link>
                <Link href="/general-sales-conditions" color="secondary">
                    <Typography variant="body2" color="secondary">
                        Conditions Générales de Vente
                    </Typography>
                </Link>
            </PerfectScrollbar>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [matchUpMd]
    )

    const container =
        window !== undefined ? () => window.document.body : undefined

    return (
        <Box
            component="nav"
            sx={{
                flexShrink: { md: 0 },
                width: matchUpMd ? drawerWidth : 'auto'
            }}
            aria-label="mailbox folders"
        >
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={() => dispatch(openDrawer(!drawerOpen))}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        [theme.breakpoints.up('md')]: {
                            top: '60px'
                        }
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawerOpen && logo}
                {drawerOpen && drawer}
            </Drawer>
        </Box>
    )
}

Sidebar.propTypes = {
    window: PropTypes.object
}

export default memo(Sidebar)
