import { styled } from '@mui/material/styles'
import { Outlet } from 'react-router-dom'
import PublicErrorBoundary from 'views/surveys/public/PublicErrorBoundary'
import Footer from './Footer'
import AppBar from './Header'

const HeaderWrapper = styled('div')(({ theme }) => ({
    paddingTop: 0,
    overflowX: 'hidden',
    overflowY: 'clip',
    [theme.breakpoints.down('md')]: {
        paddingTop: 42
    }
}))

const SecondWrapper = styled('div')(({ theme }) => ({
    paddingTop: 0,
    [theme.breakpoints.up('sm')]: {
        paddingBottom: 50
    },
    [theme.breakpoints.down('sm')]: {
        paddingBottom: 70
    },
    [theme.breakpoints.down('md')]: {
        paddingTop: 0
    }
}))

const PublicLayout = ({ withAppBar }) => {
    return (
        <>
            <HeaderWrapper id="public.home">
                {withAppBar ? <AppBar /> : <></>}
            </HeaderWrapper>
            <SecondWrapper>
                <PublicErrorBoundary>
                    <Outlet />
                </PublicErrorBoundary>
            </SecondWrapper>
            <Footer />
        </>
    )
}

export default PublicLayout
