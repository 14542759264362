import MainLayout from 'layout/MainLayout'
import { lazy } from 'react'
import AuthGuard from 'utils/route-guard/AuthGuard'

import Loadable from 'ui-component/Loadable'
import SurveyLayout from 'views/surveys/SurveyLayout'
import SurveyResponderPage from 'views/surveys/SurveyResponderPage'
import SurveyResultPage from 'views/surveys/SurveyResultPage'

const SurveyDashboardPage = Loadable(
    lazy(() => import('views/surveys/SurveyPage'))
)
const SurveyEditPage = Loadable(
    lazy(() => import('views/surveys/SurveyEditPage'))
)
const SurveyCollectorPage = Loadable(
    lazy(() => import('views/surveys/SurveyCollectorPage'))
)
const SurveyCustomizePage = Loadable(
    lazy(() => import('views/surveys/SurveyCustomizePage'))
)

const SurveyRoutes = {
    path: '/dashboard/surveys',
    element: (
        <AuthGuard>
            <MainLayout layout={<SurveyLayout />} />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dashboard/surveys/:id',
            element: <SurveyDashboardPage />
        },
        {
            path: '/dashboard/surveys/:id/edit',
            element: <SurveyEditPage />
        },
        {
            path: '/dashboard/surveys/:id/collectors',
            element: <SurveyCollectorPage />
        },
        {
            path: '/dashboard/surveys/:id/customize',
            element: <SurveyCustomizePage />
        },
        {
            path: '/dashboard/surveys/:id/responses',
            element: <SurveyResultPage />
        },
        {
            path: '/dashboard/surveys/:id/responders',
            element: <SurveyResponderPage />
        }
    ]
}

export default SurveyRoutes
