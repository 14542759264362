import MainLayout from 'layout/MainLayout'
import { lazy } from 'react'
import Loadable from 'ui-component/Loadable'
import AuthGuard from 'utils/route-guard/AuthGuard'

const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')))
const SurveyList = Loadable(lazy(() => import('views/surveys/SurveyList')))
const NewSurveyPage = Loadable(
    lazy(() => import('views/surveys/NewSurveyPage'))
)
const ProfilePage = Loadable(lazy(() => import('views/profile')))

const MainRoutes = {
    path: '/dashboard',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dashboard',
            element: <DashboardDefault />
        },
        {
            path: '/dashboard/profile',
            element: <ProfilePage />
        },
        {
            path: '/dashboard/surveys/',
            element: <SurveyList />
        },
        {
            path: '/dashboard/new-survey',
            element: <NewSurveyPage />,
            breadcrumb: false
        }
    ]
}

export default MainRoutes
