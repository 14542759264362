import {
    IconAffiliate,
    IconDashboard,
    IconDeviceAnalytics,
    IconEditCircle,
    IconPalette,
    IconReportAnalytics,
    IconUserCircle
} from '@tabler/icons-react'
import { surveyStatusChip } from 'views/surveys/survey-utils'
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconUserCircle
}

const surveys = (survey) => ({
    id: 'surveys',
    title: <>Sondage : {survey.name}</>,
    caption: surveyStatusChip(survey.status),
    type: 'group',
    children: [
        {
            id: 'my-survey',
            title: 'Mon sondage',
            type: 'item',
            regexUrl: /\/dashboard\/surveys\/(\S+)/,
            url: `/dashboard/surveys/${survey._id}`,
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'edit-survey',
            title: 'Editer le sondage',
            type: 'item',
            regexUrl: /\/dashboard\/surveys\/(\S+)\/edit/,
            url: `/dashboard/surveys/${survey._id}/edit`,
            icon: IconEditCircle,
            breadcrumbs: false,
            chip: !survey.hasContent
                ? {
                      label: 'TODO',
                      variant: 'filled',
                      size: 'small',
                      color: 'error'
                  }
                : {
                      label: 'Ok',
                      variant: 'filled',
                      size: 'small',
                      color: 'success'
                  }
        },
        {
            id: 'survey-collectors',
            title: 'Editer les collecteurs',
            type: 'item',
            regexUrl: /\/dashboard\/surveys\/(\S+)\/collectors/,
            url: `/dashboard/surveys/${survey._id}/collectors`,
            icon: IconEditCircle,
            breadcrumbs: false
        },
        {
            id: 'survey-theme',
            title: 'Personnalisation',
            type: 'item',
            regexUrl: /\/dashboard\/surveys\/(\S+)\/customize/,
            url: `/dashboard/surveys/${survey._id}/customize`,
            icon: IconPalette,
            breadcrumbs: false
        },
        {
            id: 'survey-result',
            title: `Réponses`,
            type: 'item',
            regexUrl: /\/dashboard\/surveys\/(\S+)\/responses/,
            url: `/dashboard/surveys/${survey._id}/responses`,
            icon: IconReportAnalytics,
            breadcrumbs: false,
            chip: {
                label: survey.nbResponses,
                color: 'warning',
                size: 'small'
            }
        },
        {
            id: 'survey-responders',
            title: `Répondants (${survey.nbResponses})`,
            type: 'item',
            regexUrl: /\/dashboard\/surveys\/(\S+)\/responders/,
            url: `/dashboard/surveys/${survey._id}/responders`,
            icon: IconAffiliate,
            breadcrumbs: false
        }
    ]
})

export default surveys
