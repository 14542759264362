import { Link } from 'react-router-dom'

// material-ui
import {
    Box,
    Divider,
    Grid,
    Stack,
    Typography,
    useMediaQuery
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

// project imports
import Logo from 'ui-component/Logo'
import AuthFooter from 'ui-component/cards/AuthFooter'
import AuthCardWrapper from './AuthCardWrapper'
import AuthWrapper from './AuthWrapper'
import AuthRegister from './auth-forms/AwsCognitoRegister'

const RegistrationPage = () => {
    const theme = useTheme()
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'))
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'))

    return (
        <AuthWrapper>
            <Grid
                container
                direction="column"
                justifyContent="flex-end"
                sx={{ minHeight: '100vh' }}
            >
                <Grid item md={6} lg={7} xs={12} sx={{ minHeight: '100vh' }}>
                    <Grid
                        sx={{ minHeight: '100vh' }}
                        container
                        alignItems={matchDownSM ? 'center' : 'flex-start'}
                        justifyContent={
                            matchDownSM ? 'center' : 'space-between'
                        }
                    >
                        <Grid
                            item
                            sx={{ display: { xs: 'none', md: 'block' }, m: 3 }}
                        >
                            <Link to="#">
                                <Logo />
                            </Link>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            container
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                minHeight: {
                                    xs: 'calc(100vh - 68px)',
                                    md: 'calc(100vh - 152px)'
                                }
                            }}
                        >
                            <Stack
                                justifyContent="center"
                                alignItems="center"
                                spacing={5}
                                m={2}
                            >
                                <Box
                                    component={Link}
                                    to="#"
                                    sx={{
                                        display: { xs: 'block', md: 'none' }
                                    }}
                                >
                                    <Logo />
                                </Box>
                                <AuthCardWrapper border={matchDownMD}>
                                    <Grid
                                        container
                                        spacing={2}
                                        justifyContent="center"
                                    >
                                        <Grid item xs={12}>
                                            <Stack
                                                alignItems="center"
                                                justifyContent="center"
                                                spacing={1}
                                            >
                                                <Typography
                                                    color={
                                                        theme.palette.primary
                                                            .main
                                                    }
                                                    gutterBottom
                                                    variant={
                                                        matchDownSM
                                                            ? 'h3'
                                                            : 'h2'
                                                    }
                                                >
                                                    Inscription à la plateforme
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <AuthRegister />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid
                                                item
                                                container
                                                direction="column"
                                                alignItems="center"
                                                xs={12}
                                            >
                                                <Typography
                                                    component={Link}
                                                    to="/dashboard/login"
                                                    variant="subtitle1"
                                                    sx={{
                                                        textDecoration: 'none'
                                                    }}
                                                >
                                                    Vous avez deja un compte ?
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </AuthCardWrapper>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sx={{ m: 3 }}>
                            <AuthFooter />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </AuthWrapper>
    )
}

export default RegistrationPage
