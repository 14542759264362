import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import {
    IconBrandFacebook,
    IconCloudQuestion,
    IconDashboard,
    IconDeviceAnalytics,
    IconPackage,
    IconQuestionMark,
    IconShoppingCart,
    IconSubscript
} from '@tabler/icons-react'

const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconSubscript,
    IconShoppingCart,
    IconBrandFacebook,
    IconPackage,
    IconQuestionMark,
    HelpOutlineIcon,
    IconCloudQuestion
}

const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Mon Dashboard',
            type: 'item',
            url: '/dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: true,
            regexUrl: /^\/dashboard\/?$/
        },
        {
            id: 'surveys',
            title: 'Mes sondages',
            type: 'item',
            url: '/dashboard/surveys',
            regexUrl: /^\/dashboard\/surveys\/?$/,
            icon: icons.IconCloudQuestion,
            breadcrumbs: true
        }
    ]
}

export default dashboard
