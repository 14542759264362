// action - state management
import { LOGIN, LOGOUT, REGISTER, NEW_PASSWORD_REQUIRED, CHANGE_PASSWORD_ERROR, END_REGISTER } from './actions';


const accountReducer = (state, action) => {
    switch (action.type) {
        case REGISTER: {
            const { user } = action.payload;
            return {
                ...state,
                user
            };
        }
        case NEW_PASSWORD_REQUIRED: {
            const { user } = action.payload;
            return {
                ...state,
                isLoggedIn: false,
                isRegistrationNeeded: true,
                isInitialized: true,
                user
            };
        }
        case END_REGISTER: {
            return {
                ...state,
                isRegistrationNeeded: false
            }
        }
        case CHANGE_PASSWORD_ERROR: {
            return {
                ...state,
                hasPasswordEditError: true
            }
        }
        case LOGIN: {
            const { user } = action.payload;
            return {
                ...state,
                isLoggedIn: true,
                isInitialized: true,
                user
            };
        }
        case LOGOUT: {
            return {
                ...state,
                isInitialized: true,
                isLoggedIn: false,
                user: null
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default accountReducer;
