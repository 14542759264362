export const fromIsoDateToLocaleDate = (date, locale = 'fr') => {
    const d = new Date(date)
    return d
        ? `${d.toLocaleDateString(locale)} ${d.toLocaleTimeString(locale)}`
        : ''
}

export const fromIsoDateToLocaleTime = (date, locale = 'fr') => {
    const d = new Date(date)
    return d ? `${d.toLocaleTimeString(locale)}` : ''
}

export const fromIsoDateToLocaleOnlyDate = (date, locale = 'fr') => {
    const d = new Date(date)
    return d ? `${d.toLocaleDateString(locale)}` : ''
}

export const fromTimestampToLocaleDate = (timestamp, locale = 'fr') => {
    const d = new Date(timestamp)
    return d
        ? `${d.toLocaleDateString(locale)} ${d.toLocaleTimeString(locale)}`
        : ''
}
