import { Box } from '@mui/material'

const SurveyCustomLogo = ({ surveyCustomizationLogo, height = 75 }) => {
    return (
        <Box
            component={'img'}
            src={surveyCustomizationLogo}
            sx={{
                height: height
            }}
        />
    )
}

export default SurveyCustomLogo
