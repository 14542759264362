import { Typography } from '@mui/material'
import menuItem from 'menu-items'
import surveys from 'menu-items/surveys'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import NavGroup from './NavGroup'

// ==============================|| SIDEBAR MENU LIST ||============================== //

const SURVEY_REGEX = /\/surveys\/(\d)([\w,\W,/]*)/
const MenuList = () => {
    const survey = useSelector((state) => state.survey.survey)
    const location = useLocation()

    console.log('survey in MenuList', survey)
    let menus = [...menuItem.items]
    if (survey && location.pathname.match(SURVEY_REGEX)) {
        menus = [...menuItem.items, surveys(survey)]
    }

    const navItems = menus.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />
            default:
                return (
                    <Typography
                        key={item.id}
                        variant="h6"
                        color="error"
                        align="center"
                    >
                        Menu Items Error
                    </Typography>
                )
        }
    })

    return <>{navItems}</>
}

export default MenuList
