import { lazy } from 'react'

// project imports
import { LOGIN_PATH } from 'config'
import MinimalLayout from 'layout/MinimalLayout'
import NavMotion from 'layout/NavMotion'
import Loadable from 'ui-component/Loadable'
import GuestGuard from 'utils/route-guard/GuestGuard'
import RegistrationPage from 'views/authentication/RegistrationPage'
const LoginPage = Loadable(lazy(() => import('views/authentication/LoginPage')))
const FinishRegistrationPage = Loadable(
    lazy(() => import('views/authentication/FinishRegistrationPage'))
)
const ForgotPasswordPage = Loadable(
    lazy(() => import('views/authentication/ForgotPasswordPage'))
)
const ForgotPasswordPage2 = Loadable(
    lazy(() => import('views/authentication/ForgotPasswordPage2'))
)
// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/verifyEmail',
            element: <FinishRegistrationPage />
        },
        {
            path: LOGIN_PATH,
            element: <LoginPage />
        },
        {
            path: '/register',
            element: <RegistrationPage />
        },
        {
            path: '/forgot',
            element: <ForgotPasswordPage />
        },
        {
            path: '/forgot/code',
            element: <ForgotPasswordPage2 />
        }
    ]
}

export default LoginRoutes
