import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import MainCard from 'ui-component/cards/MainCard'
import { fromTimestampToLocaleDate } from 'utils/dates'
import Error from 'views/ui-elements/Error'

const SurveyResponderPage = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [responses, setResponses] = useState(null)
    const [error, setError] = useState(null)
    const { id } = useParams()
    useEffect(() => {
        setIsLoading(true)
        setError(false)
        axios
            .get(`/surveys/${id}/responders`)
            .then((res) => {
                setResponses(res.data)
                setIsLoading(false)
            })
            .catch((err) => {
                setError(true)
                setIsLoading(false)
            })
    }, [])

    if (isLoading) {
        return 'Chargement en cours'
    }

    if (error || !responses) {
        return <Error />
    }

    return (
        <>
            <MainCard
                title={
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                    >
                        <Grid item>
                            <Typography variant="h3">
                                Répondants du sondage
                            </Typography>
                        </Grid>
                    </Grid>
                }
                content={true}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            Adresse IP du répondant
                                        </TableCell>
                                        <TableCell>Collecteur</TableCell>
                                        <TableCell>Répondu le </TableCell>
                                        <TableCell>
                                            Nb questions répondues
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {responses.map((response, index) => (
                                        <TableRow>
                                            <TableCell>
                                                {response.ipAddress}
                                            </TableCell>
                                            <TableCell>
                                                {response.collectorName} &nbsp;(
                                                {response.collectorId})
                                            </TableCell>
                                            <TableCell>
                                                {fromTimestampToLocaleDate(
                                                    response.createdAt
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {response.nbResponses}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </MainCard>
        </>
    )
}

export default SurveyResponderPage
