import { Avatar, Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { IconMenu2 } from '@tabler/icons-react'
import { useDispatch, useSelector } from 'store'
import { openDrawer } from 'store/slices/menu'
import LogoSection from '../LogoSection'
import ProfileSection from './ProfileSection'

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const theme = useTheme()

    const dispatch = useDispatch()
    const { drawerOpen } = useSelector((state) => state.menu)

    return (
        <>
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box
                    component="span"
                    sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}
                >
                    <LogoSection />
                </Box>
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        overflow: 'hidden',
                        transition: 'all .2s ease-in-out',
                        background:
                            theme.palette.mode === 'dark'
                                ? theme.palette.primary.main
                                : theme.palette.primary.light,
                        color:
                            theme.palette.mode === 'dark'
                                ? theme.palette.primary.main
                                : theme.palette.primary.dark,
                        '&:hover': {
                            background:
                                theme.palette.mode === 'dark'
                                    ? theme.palette.primary.main
                                    : theme.palette.primary.dark,
                            color:
                                theme.palette.mode === 'dark'
                                    ? theme.palette.primary.light
                                    : theme.palette.primary.light
                        }
                    }}
                    onClick={() => dispatch(openDrawer(!drawerOpen))}
                    color="inherit"
                >
                    <IconMenu2 stroke={1.5} size="1.3rem" />
                </Avatar>
            </Box>

            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            <Box sx={{ flexGrow: 0.1 }} />

            <ProfileSection />
        </>
    )
}

export default Header
