import {
    Container,
    Link,
    Stack,
    Typography,
    useMediaQuery
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { Link as RouterLink } from 'react-router-dom'

const Footer = styled('div')(({ theme }) => ({
    width: '100%',
    position: 'fixed',
    bottom: 0
}))

const FooterSubWrapper = styled('div')(({ theme }) => ({
    padding: '4px 0',
    color: '#fff',
    background: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
        textAlign: 'center'
    }
}))

const FooterPage = () => {
    const theme = useTheme()
    const isResponsive = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <>
            <Footer>
                <FooterSubWrapper>
                    <Container>
                        <Stack
                            direction="row"
                            spacing={{ xs: 0, md: 10 }}
                            justifyContent={
                                isResponsive ? 'start' : 'space-around'
                            }
                        >
                            <Typography
                                variant="subtitle2"
                                component="div"
                                fontWeight="bold"
                                color="inherit"
                            >
                                Propulsé par &#169; SPOKINGPOLLS
                            </Typography>
                            <Link
                                component={RouterLink}
                                to="/legal"
                                sx={{ textDecorationColor: 'white' }}
                            >
                                <Typography variant="body2" color="white">
                                    Mentions légales
                                </Typography>
                            </Link>
                            <Link
                                component={RouterLink}
                                to="/terms"
                                sx={{ textDecorationColor: 'white' }}
                            >
                                <Typography variant="body2" color="white">
                                    Conditions d'utilisation
                                </Typography>
                            </Link>
                            <Link
                                to="#"
                                sx={{
                                    textDecorationColor: 'white',
                                    cursor: 'pointer'
                                }}
                                onClick={(e) => {
                                    window.location.href =
                                        'mailto:contact@spokingpolls.com'
                                    e.preventDefault()
                                }}
                            >
                                <Typography variant="body2" color="white">
                                    Contactez-nous
                                </Typography>
                            </Link>
                            {!isResponsive && (
                                <Typography
                                    variant="caption"
                                    component="div"
                                    color="inherit"
                                >
                                    {`v${process.env.REACT_APP_VERSION}`}
                                </Typography>
                            )}
                        </Stack>
                    </Container>
                </FooterSubWrapper>
            </Footer>
        </>
    )
}

export default FooterPage
