import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { omit } from 'lodash'
import update from 'react-addons-update'
import { openSnackbarWithError } from 'utils/errors'
import { dispatch } from '../index'
import { openSnackbar } from './snackbar'
const initialState = {
    pageOpenedValue: 0,
    error: null,
    survey: null,
    editingQuestionId: null,
    isLoadingSurvey: false,
    isEditingSurvey: false,
    isAddingQuestion: false,
    isEditingThemeSurvey: false,
    isUploadingSurveyImage: false,
    isUploadingSurveyFavicon: false,
    isLoadingStats: false
}

const slice = createSlice({
    name: 'survey',
    initialState,
    reducers: {
        resetAlbum(state) {
            state.album = null
        },

        getSurvey(state) {
            state.isLoadingSurvey = true
        },

        getSurveySuccess(state, action) {
            state.survey = action.payload
            state.isLoadingSurvey = false
        },

        getSurveyFailure(state) {
            state.survey = null
            state.isLoadingSurvey = false
        },

        getSurveyStats(state) {
            state.isLoadingStats = true
        },

        getSurveyStatsSuccess(state, action) {
            state.survey = update(state.survey, {
                stats: {
                    $set: action.payload
                },
                nbResponses: {
                    $set: action.payload.nbResponses
                }
            })
            state.isLoadingStats = false
        },

        getSurveyStatsFailure(state) {
            state.isLoadingStats = false
        },

        editSurvey(state) {
            state.isEditingSurvey = true
        },

        editSurveySuccess(state, action) {
            state.isEditingSurvey = false
            state.survey = action.payload
        },

        editSurveyFailure(state, action) {
            state.isEditingSurvey = false
        },

        uploadSurveyImage(state) {
            state.isUploadingSurveyImage = true
        },
        uploadSurveyImageSuccess(state, action) {
            state.survey = update(state.survey, {
                customization: {
                    surveyLogo: {
                        $set: action.payload.surveyLogo
                    }
                }
            })
            state.isUploadingSurveyImage = false
        },
        uploadSurveyImageFailure(state) {
            state.isUploadingSurveyImage = false
        },

        uploadSurveyFavicon(state) {
            state.isUploadingSurveyFavicon = true
        },
        uploadSurveyFaviconSuccess(state, action) {
            state.survey = update(state.survey, {
                customization: {
                    surveyFavicon: {
                        $set: action.payload.surveyFavicon
                    }
                }
            })
            state.isUploadingSurveyFavicon = false
        },
        uploadSurveyFaviconFailure(state) {
            state.isUploadingSurveyFavicon = false
        },

        editSurveyTheme(state) {
            state.isEditingThemeSurvey = true
        },
        editSurveyThemeSuccess(state, action) {
            state.survey = update(state.survey, {
                customization: {
                    $set: action.payload
                }
            })
            state.isEditingThemeSurvey = false
        },
        editSurveyThemeFailure(state) {
            state.isEditingThemeSurvey = false
        },

        addQuestionSuccess(state, action) {
            const { pageId, question } = action.payload
            const indexPage = state.survey.pages.findIndex(
                (p) => p._id === pageId
            )
            state.survey = update(state.survey, {
                pages: {
                    [indexPage]: {
                        questions: {
                            $push: [question]
                        }
                    }
                },
                hasContent: {
                    $set: true
                }
            })
            state.isAddingQuestion = false
            state.editingQuestionId = null
        },

        editQuestionSuccess(state, action) {
            const { pageId, question } = action.payload
            const indexPage = state.survey.pages.findIndex(
                (p) => p._id === pageId
            )
            const indexQuestion = state.survey.pages[
                indexPage
            ].questions.findIndex((q) => q._id === question._id)
            state.survey = update(state.survey, {
                pages: {
                    [indexPage]: {
                        questions: {
                            [indexQuestion]: {
                                $set: question
                            }
                        }
                    }
                }
            })
            state.editingQuestionId = null
        },

        addQuestionFailure(state, action) {},

        moveBeforeQuestion(state) {},
        moveBeforeQuestionSuccess(state, action) {
            const { pageId, questionId } = action.payload
            const indexPage = state.survey.pages.findIndex(
                (p) => p._id === pageId
            )
            const indexQuestion = state.survey.pages[
                indexPage
            ].questions.findIndex((q) => q._id === questionId)
            const questionsReArranged = [
                ...state.survey.pages[indexPage].questions
            ]
            const previousQuestion = questionsReArranged[indexQuestion - 1]
            questionsReArranged[indexQuestion - 1] =
                questionsReArranged[indexQuestion]
            questionsReArranged[indexQuestion] = previousQuestion
            state.survey = update(state.survey, {
                pages: {
                    [indexPage]: {
                        questions: {
                            $set: questionsReArranged
                        }
                    }
                }
            })
        },
        moveBeforeQuestionFailure(state) {},
        moveAfterQuestion(state) {},
        moveAfterQuestionSuccess(state, action) {
            const { pageId, questionId } = action.payload
            const indexPage = state.survey.pages.findIndex(
                (p) => p._id === pageId
            )
            const indexQuestion = state.survey.pages[
                indexPage
            ].questions.findIndex((q) => q._id === questionId)
            const questionsReArranged = [
                ...state.survey.pages[indexPage].questions
            ]
            const previousQuestion = questionsReArranged[indexQuestion + 1]
            questionsReArranged[indexQuestion + 1] =
                questionsReArranged[indexQuestion]
            questionsReArranged[indexQuestion] = previousQuestion
            state.survey = update(state.survey, {
                pages: {
                    [indexPage]: {
                        questions: {
                            $set: questionsReArranged
                        }
                    }
                }
            })
        },
        moveAfterQuestionFailure(state) {},

        changePageQuestionSuccess(state, action) {
            const { questionId, pageId, newPageId } = action.payload
            const indexPage = state.survey.pages.findIndex(
                (p) => p._id === pageId
            )
            const indexQuestion = state.survey.pages[
                indexPage
            ].questions.findIndex((q) => q._id === questionId)
            const questionsReArranged = [
                ...state.survey.pages[indexPage].questions
            ]
            const questionToMove = questionsReArranged[indexQuestion]
            questionsReArranged.splice(indexQuestion, 1)
            const indexNewPage = state.survey.pages.findIndex(
                (p) => p._id === newPageId
            )
            state.survey = update(state.survey, {
                pages: {
                    [indexPage]: {
                        questions: {
                            $set: questionsReArranged
                        }
                    },
                    [indexNewPage]: {
                        questions: {
                            $push: [questionToMove]
                        }
                    }
                }
            })
            state.pageOpenedValue = indexNewPage
        },

        deleteQuestion(state, action) {},

        deleteQuestionSuccess(state, action) {
            const { pageId, questionId } = action.payload
            const indexPage = state.survey.pages.findIndex(
                (p) => p._id === pageId
            )
            const indexQuestion = state.survey.pages[
                indexPage
            ].questions.findIndex((q) => q._id === questionId)
            state.survey = update(state.survey, {
                pages: {
                    [indexPage]: {
                        questions: {
                            $splice: [[indexQuestion, 1]]
                        }
                    }
                }
            })
        },

        deleteQuestionFailure(state, action) {},

        startAddingQuestion(state, action) {
            state.isAddingQuestion = true
        },

        startEditingQuestion(state, action) {
            state.editingQuestionId = action.payload
        },

        stopEditingQuestion(state, action) {
            state.isAddingQuestion = false
            state.editingQuestionId = null
        },

        addPageSuccess(state, action) {
            const { page } = action.payload
            state.survey = update(state.survey, {
                pages: {
                    $push: [page]
                },
                hasContent: {
                    $set: true
                }
            })
        },

        addPageFailure(state, action) {},

        updatePageSuccess(state, action) {
            const { page } = action.payload
            const index = state.survey.pages.findIndex(
                (p) => p._id === page._id
            )
            state.survey = update(state.survey, {
                pages: {
                    [index]: {
                        $set: {
                            questions: [...page.questions],
                            ...page
                        }
                    }
                }
            })
        },

        moveBeforePage(state) {
            state.isUpdatingPageOrder = true
        },
        moveBeforePageSuccess(state, action) {
            const { pageId } = action.payload
            const index = state.survey.pages.findIndex((p) => p._id === pageId)
            const pagesReArranged = [...state.survey.pages]
            const previousPage = pagesReArranged[index - 1]
            pagesReArranged[index - 1] = pagesReArranged[index]
            pagesReArranged[index] = previousPage
            state.survey = update(state.survey, {
                pages: {
                    $set: pagesReArranged
                }
            })
            state.isUpdatingPageOrder = false
            state.pageOpenedValue = state.pageOpenedValue - 1
        },
        moveBeforePageFailure(state) {
            state.isUpdatingPageOrder = false
        },
        moveAfterPage(state) {
            state.isUpdatingPageOrder = true
        },
        moveAfterPageSuccess(state, action) {
            const { pageId } = action.payload
            const index = state.survey.pages.findIndex((p) => p._id === pageId)
            const pagesReArranged = [...state.survey.pages]
            const previousPage = pagesReArranged[index + 1]
            pagesReArranged[index + 1] = pagesReArranged[index]
            pagesReArranged[index] = previousPage
            state.survey = update(state.survey, {
                pages: {
                    $set: pagesReArranged
                }
            })
            state.isUpdatingPageOrder = false
            state.pageOpenedValue = state.pageOpenedValue + 1
        },
        moveAfterPageFailure(state) {
            state.isUpdatingPageOrder = false
        },

        deletePageSuccess(state, action) {
            const { pageId } = action.payload
            const index = state.survey.pages.findIndex((p) => p._id === pageId)
            state.survey = update(state.survey, {
                pages: {
                    $splice: [[index, 1]]
                }
            })
            state.pageOpenedValue = Math.max(0, state.pageOpenedValue - 1)
        },

        addPageNotSavedYet(state) {
            const index = state.survey.pages.length + 1
            state.survey = update(state.survey, {
                pages: {
                    $push: [
                        {
                            name: `Ma nouvelle page #${index}`,
                            questions: [],
                            isNotSaved: true
                        }
                    ]
                }
            })
        },

        setTabValue(state, action) {
            state.pageOpenedValue = action.payload
        },

        // =========== COLLLECTORS ============
        createCollector(state) {},

        editCollectorSuccess(state, action) {
            const { collectorId, collector } = action.payload
            const index = state.survey.collectors.findIndex(
                (c) => c._id === collectorId
            )
            state.survey = update(state.survey, {
                collectors: {
                    [index]: {
                        $set: collector
                    }
                }
            })
        },

        createCollectorSuccess(state, action) {
            state.survey = update(state.survey, {
                collectors: {
                    $push: [action.payload]
                },
                hasCollector: {
                    $set: true
                }
            })
        },

        createCollectorFailure(state) {},

        getCollectors(state, action) {},

        getCollectorsSuccess(state, action) {
            state.survey = update(state.survey, {
                collectors: {
                    $set: action.payload
                }
            })
        },

        deleteCollectorSuccess(state, action) {
            const index = state.survey.collectors.findIndex(
                (c) => c._id === action.payload.collectorId
            )
            state.survey = update(state.survey, {
                collectors: {
                    $splice: [[index, 1]]
                }
            })
        },

        // ============= STATUS =========
        changeSurveyStatus(state, action) {},

        changeSurveyStatusSuccess(state, action) {
            state.survey = update(state.survey, {
                status: { $set: action.payload.status }
            })
        },

        changeSurveyStatusFailure(state, action) {}
    }
})

export default slice.reducer

export function editSurvey(survey) {
    return async (_, getState) => {
        try {
            dispatch(slice.actions.editSurvey())
            const response = await axios.put(
                `/surveys/${getState().survey.survey._id}`,
                {
                    name: survey.name,
                    description: survey.description
                }
            )
            dispatch(slice.actions.editSurveySuccess(response.data))
        } catch (error) {
            dispatch(slice.actions.editSurveyFailure())
        }
    }
}

export function editSurveyTheme(customization) {
    return async (_, getState) => {
        try {
            dispatch(slice.actions.editSurveyTheme())
            const response = await axios.put(
                `/surveys/${getState().survey.survey._id}/theme`,
                {
                    ...customization
                }
            )
            dispatch(slice.actions.editSurveyThemeSuccess(response.data))
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Thème du sondage mis à jour',
                    variant: 'default',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            )
        } catch (error) {
            openSnackbarWithError(error, dispatch)
            dispatch(slice.actions.editSurveyThemeFailure())
        }
    }
}

export function uploadSurveyImage(image) {
    return async (_, getState) => {
        try {
            dispatch(slice.actions.uploadSurveyImage())
            const formData = new FormData()
            formData.append('image', image)
            const response = await axios.put(
                `/surveys/${getState().survey.survey._id}/logo/upload`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
            dispatch(slice.actions.uploadSurveyImageSuccess(response.data))
        } catch (error) {
            dispatch(slice.actions.uploadSurveyImageFailure())
        }
    }
}

export function uploadSurveyFavicon(image) {
    return async (_, getState) => {
        try {
            dispatch(slice.actions.uploadSurveyFavicon())
            const formData = new FormData()
            formData.append('image', image)
            const response = await axios.put(
                `/surveys/${getState().survey.survey._id}/favicon/upload`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
            dispatch(slice.actions.uploadSurveyFaviconSuccess(response.data))
        } catch (error) {
            dispatch(slice.actions.uploadSurveyFaviconFailure())
        }
    }
}

export function getSurvey(id, withRefresh = false) {
    return async (_, getState) => {
        try {
            if (!withRefresh) {
                dispatch(slice.actions.getSurvey())
            }
            const response = await axios.get(`/surveys/${id}`)
            dispatch(slice.actions.getSurveySuccess(response.data))
        } catch (error) {
            dispatch(slice.actions.getSurveyFailure())
        }
    }
}

export function getSurveyStats(id, withRefresh = false) {
    return async (_, getState) => {
        try {
            if (!withRefresh) {
                dispatch(slice.actions.getSurveyStats())
            }
            const response = await axios.get(`/surveys/${id}/stats`)
            dispatch(slice.actions.getSurveyStatsSuccess(response.data))
        } catch (error) {
            dispatch(slice.actions.getSurveyStatsFailure())
        }
    }
}

export function addPageNotSavedYet(page) {
    return async () => {
        dispatch(slice.actions.addPageNotSavedYet())
    }
}

export function addPage(index) {
    return async (_, getState) => {
        const page = {
            name: `Ma nouvelle page #${index}`
        }
        try {
            const response = await axios.post(
                `/surveys/${getState().survey.survey._id}/pages`,
                page
            )
            dispatch(
                slice.actions.addPageSuccess({
                    page: response.data
                })
            )
        } catch (error) {
            openSnackbarWithError(error, dispatch)
            dispatch(slice.actions.addPageFailure())
        }
    }
}

export function editPage(pageId, page) {
    return async (_, getState) => {
        try {
            const response = await axios.put(
                `/surveys/${getState().survey.survey._id}/pages/${pageId}`,
                {
                    name: page.name,
                    description: page.description
                }
            )
            dispatch(
                slice.actions.updatePageSuccess({
                    page: response.data
                })
            )
        } catch (error) {
            openSnackbarWithError(error, dispatch)
        }
    }
}

export function deletePage(pageId) {
    return async (_, getState) => {
        try {
            await axios.delete(
                `/surveys/${getState().survey.survey._id}/pages/${pageId}`
            )
            dispatch(
                slice.actions.deletePageSuccess({
                    pageId: pageId
                })
            )
        } catch (error) {
            openSnackbarWithError(error, dispatch)
            // dispatch(slice.actions.updatePageFailure());
        }
    }
}

export function moveBeforePage(pageId) {
    return async (_, getState) => {
        try {
            await axios.post(
                `/surveys/${
                    getState().survey.survey._id
                }/pages/${pageId}/move-before`
            )
            dispatch(slice.actions.moveBeforePageSuccess({ pageId: pageId }))
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Page déplacée plus tôt avec succès',
                    variant: 'default',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            )
        } catch (error) {
            openSnackbarWithError(error, dispatch)
        }
    }
}

export function moveAfterPage(pageId) {
    return async (_, getState) => {
        try {
            await axios.post(
                `/surveys/${
                    getState().survey.survey._id
                }/pages/${pageId}/move-after`
            )
            dispatch(slice.actions.moveAfterPageSuccess({ pageId: pageId }))
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Page décalée plus loin avec succès',
                    variant: 'default',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            )
        } catch (error) {
            openSnackbarWithError(error, dispatch)
        }
    }
}

export function addQuestion(question, pageId) {
    return async (_, getState) => {
        try {
            const response = await axios.post(
                `/surveys/${
                    getState().survey.survey._id
                }/pages/${pageId}/questions`,
                question
            )
            dispatch(
                slice.actions.addQuestionSuccess({
                    question: response.data,
                    pageId: pageId
                })
            )
        } catch (error) {
            openSnackbarWithError(error, dispatch)
            dispatch(slice.actions.addQuestionFailure())
        }
    }
}

export function duplicateQuestion(questionIdToCopy, pageId) {
    return async (_, getState) => {
        try {
            const questionToCopy = getState()
                .survey.survey.pages.find((p) => p._id === pageId)
                .questions.find((q) => q._id === questionIdToCopy)
            const response = await axios.post(
                `/surveys/${
                    getState().survey.survey._id
                }/pages/${pageId}/questions`,
                {
                    ...questionToCopy,
                    title: `${questionToCopy.title} (copie)`
                }
            )
            dispatch(
                slice.actions.addQuestionSuccess({
                    question: response.data,
                    pageId: pageId
                })
            )
        } catch (error) {
            openSnackbarWithError(error, dispatch)
            dispatch(slice.actions.addQuestionFailure())
        }
    }
}

export function deleteQuestion(questionId, pageId) {
    return async (_, getState) => {
        dispatch(slice.actions.deleteQuestion())
        try {
            await axios.delete(
                `/surveys/${
                    getState().survey.survey._id
                }/pages/${pageId}/questions/${questionId}`
            )
            dispatch(
                slice.actions.deleteQuestionSuccess({
                    questionId: questionId,
                    pageId: pageId
                })
            )
        } catch (error) {
            openSnackbarWithError(error, dispatch)
            dispatch(slice.actions.deleteQuestionFailure())
        }
    }
}

export function moveBeforeQuestion(questionId, pageId) {
    return async (_, getState) => {
        dispatch(slice.actions.moveBeforeQuestion())
        try {
            await axios.post(
                `/surveys/${
                    getState().survey.survey._id
                }/pages/${pageId}/questions/${questionId}/move-before`
            )
            dispatch(
                slice.actions.moveBeforeQuestionSuccess({
                    questionId: questionId,
                    pageId: pageId
                })
            )
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Ordre des questions mis à jour',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            )
        } catch (error) {
            openSnackbarWithError(error, dispatch)
            dispatch(slice.actions.moveBeforeQuestionFailure())
        }
    }
}

export function moveAfterQuestion(questionId, pageId) {
    return async (_, getState) => {
        dispatch(slice.actions.moveAfterQuestion())
        try {
            await axios.post(
                `/surveys/${
                    getState().survey.survey._id
                }/pages/${pageId}/questions/${questionId}/move-after`
            )
            dispatch(
                slice.actions.moveAfterQuestionSuccess({
                    questionId: questionId,
                    pageId: pageId
                })
            )
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Ordre des questions mis à jour',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            )
        } catch (error) {
            console.warn(error)
            openSnackbarWithError(error, dispatch)
            dispatch(slice.actions.moveAfterQuestionFailure())
        }
    }
}

export function changePageQuestion(questionId, pageId, newPageId) {
    return async (_, getState) => {
        try {
            await axios.post(
                `/surveys/${
                    getState().survey.survey._id
                }/pages/${pageId}/questions/${questionId}/move-page`,
                {
                    newPageId: newPageId
                }
            )
            dispatch(
                slice.actions.changePageQuestionSuccess({
                    questionId: questionId,
                    pageId: pageId,
                    newPageId: newPageId
                })
            )
        } catch (err) {
            openSnackbarWithError(err, dispatch)
        }
    }
}

export function editQuestion(question, pageId) {
    return async (_, getState) => {
        const questionSanitized = omit(question, '_id', 'survey')
        try {
            const response = await axios.put(
                `/surveys/${
                    getState().survey.survey._id
                }/pages/${pageId}/questions/${question._id}`,
                questionSanitized
            )
            dispatch(
                slice.actions.editQuestionSuccess({
                    question: response.data,
                    pageId: pageId
                })
            )
        } catch (error) {
            openSnackbarWithError(error, dispatch)
            dispatch(slice.actions.addQuestionFailure())
        }
    }
}

export function createCollector(collector) {
    return async (_, getState) => {
        try {
            dispatch(slice.actions.createCollector())
            const response = await axios.post(
                `/surveys/${getState().survey.survey._id}/collectors/`,
                collector
            )
            dispatch(slice.actions.createCollectorSuccess(response.data))
        } catch (err) {
            openSnackbarWithError(err, dispatch)
            dispatch(slice.actions.createCollectorFailure())
        }
    }
}

export function editCollector(surveyId, collectorId, newObj, prevObj) {
    return async (_, getState) => {
        try {
            const response = await axios.put(
                `/surveys/${surveyId}/collectors/${collectorId}`,
                newObj
            )
            dispatch(
                slice.actions.editCollectorSuccess({
                    collectorId: collectorId,
                    collector: response.data
                })
            )
        } catch (err) {
            openSnackbarWithError(err, dispatch)
        }
    }
}

export function deleteCollector(surveyId, collectorId) {
    return async (_, getState) => {
        try {
            await axios.delete(`/surveys/${surveyId}/collectors/${collectorId}`)
            dispatch(
                slice.actions.deleteCollectorSuccess({
                    collectorId: collectorId
                })
            )
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Collecteur supprimé',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            )
        } catch (err) {
            openSnackbarWithError(err, dispatch)
        }
    }
}

export function getCollectors() {
    return async (_, getState) => {
        try {
            dispatch(slice.actions.getCollectors())
            const response = await axios.get(
                `/surveys/${getState().survey.survey._id}/collectors`
            )
            dispatch(slice.actions.getCollectorsSuccess(response.data))
        } catch (err) {}
    }
}

export function startEditingQuestion(questionId) {
    return async () => {
        dispatch(slice.actions.startEditingQuestion(questionId))
    }
}

export function startAddingQuestion() {
    return async () => {
        dispatch(slice.actions.startAddingQuestion())
    }
}

export function stopEditingQuestion() {
    return async () => {
        dispatch(slice.actions.stopEditingQuestion())
    }
}

export function changeTabValue(newValue) {
    return async () => {
        dispatch(slice.actions.setTabValue(newValue))
    }
}

export function changeSurveyStatus(newStatus) {
    return async (_, getState) => {
        try {
            dispatch(slice.actions.changeSurveyStatus())
            const response = await axios.post(
                `/surveys/${getState().survey.survey._id}/status`,
                {
                    status: newStatus
                }
            )
            dispatch(slice.actions.changeSurveyStatusSuccess(response.data))
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Sauvegarde effectuée ',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            )
        } catch (err) {
            dispatch(slice.actions.changeSurveyStatusFailure())
            openSnackbarWithError(err, dispatch)
        }
    }
}
