
export const BASE_PATH = '';

export const DASHBOARD_PATH = '/dashboard';
export const REGISTER_PATH = '/endregister';
export const AUTHORIZATION_CODE_PATH = '/forgot/code';
export const LOGIN_PATH = '/dashboard/login';
export const FORGOT_PATH = '/forgot';

const config = {
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'light', // light, dark
    presetColor: 'theme1', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'fr', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    container: false
};


export default config;
