import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'store'

// material-ui
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

// third party
import { Formik } from 'formik'
import * as Yup from 'yup'

// project imports
import useAuth from 'hooks/useAuth'
import useScriptRef from 'hooks/useScriptRef'
import { openSnackbar } from 'store/slices/snackbar'
import AnimateButton from 'ui-component/extended/AnimateButton'
import {
    strengthColor,
    strengthIndicatorNumFunc
} from 'utils/password-strength'

// assets
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { useIntl } from 'react-intl'

// ===========================|| FIREBASE - REGISTER ||=========================== //

const AWSCognitoRegister = ({ ...others }) => {
    const theme = useTheme()
    const navigate = useNavigate()
    const scriptedRef = useScriptRef()
    const dispatch = useDispatch()

    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'))
    const [showPassword, setShowPassword] = React.useState(false)

    const [strength, setStrength] = React.useState(0)
    const [level, setLevel] = React.useState()
    const { register } = useAuth()
    const intl = useIntl()
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    const changePassword = (value) => {
        const temp = strengthIndicatorNumFunc(value)
        setStrength(temp)
        setLevel(strengthColor(temp))
    }

    return (
        <>
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                    firstName: '',
                    lastName: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    firstName: Yup.string().required('Prénom obligatoire'),
                    lastName: Yup.string().required('Nom de famille'),
                    email: Yup.string()
                        .email("L'email doit être valide")
                        .max(255)
                        .required('Email requis'),
                    password: Yup.string()
                        .max(255)
                        .required('Mot de passe requis')
                })}
                onSubmit={async (
                    values,
                    { setErrors, setStatus, setSubmitting }
                ) => {
                    try {
                        await register(
                            values.email,
                            values.password,
                            values.firstName,
                            values.lastName
                        )
                        if (scriptedRef.current) {
                            setStatus({ success: true })
                            setSubmitting(false)
                            dispatch(
                                openSnackbar({
                                    open: true,
                                    message:
                                        'Votre inscription a été effectuée.',
                                    variant: 'alert',
                                    alert: {
                                        color: 'success'
                                    },
                                    close: false
                                })
                            )
                            setTimeout(() => {
                                navigate('/login', { replace: true })
                            }, 1500)
                        }
                    } catch (err) {
                        console.error(err)
                        if (scriptedRef.current) {
                            setStatus({ success: false })
                            setErrors({
                                submit: intl.formatMessage({
                                    id: `errors.${err.code}`
                                })
                            })
                            setSubmitting(false)
                        }
                    }
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                }) => (
                    <form noValidate onSubmit={handleSubmit} {...others}>
                        <Grid container spacing={matchDownSM ? 0 : 2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Prénom"
                                    margin="normal"
                                    name="firstName"
                                    type="text"
                                    value={values.firstName}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    sx={{ ...theme.typography.customInput }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Nom de famille"
                                    margin="normal"
                                    name="lastName"
                                    type="text"
                                    value={values.lastName}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    sx={{ ...theme.typography.customInput }}
                                />
                            </Grid>
                        </Grid>
                        <FormControl
                            fullWidth
                            error={Boolean(touched.email && errors.email)}
                            sx={{ ...theme.typography.customInput }}
                        >
                            <InputLabel htmlFor="outlined-adornment-email-register">
                                Email
                            </InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-email-register"
                                type="email"
                                value={values.email}
                                name="email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                inputProps={{}}
                            />
                            {touched.email && errors.email && (
                                <FormHelperText
                                    error
                                    id="standard-weight-helper-text--register"
                                >
                                    {errors.email}
                                </FormHelperText>
                            )}
                        </FormControl>

                        <FormControl
                            fullWidth
                            error={Boolean(touched.password && errors.password)}
                            sx={{ ...theme.typography.customInput }}
                        >
                            <InputLabel htmlFor="outlined-adornment-password-register">
                                Mot de passe
                            </InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password-register"
                                type={showPassword ? 'text' : 'password'}
                                value={values.password}
                                name="password"
                                label="Password"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    handleChange(e)
                                    changePassword(e.target.value)
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={
                                                handleMouseDownPassword
                                            }
                                            edge="end"
                                            size="large"
                                        >
                                            {showPassword ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                inputProps={{}}
                            />
                            {touched.password && errors.password && (
                                <FormHelperText
                                    error
                                    id="standard-weight-helper-text-password-register"
                                >
                                    {errors.password}
                                </FormHelperText>
                            )}
                        </FormControl>

                        {strength !== 0 && (
                            <FormControl fullWidth>
                                <Box sx={{ mb: 2 }}>
                                    <Grid
                                        container
                                        spacing={2}
                                        alignItems="center"
                                    >
                                        <Grid item>
                                            <Box
                                                style={{
                                                    backgroundColor:
                                                        level?.color
                                                }}
                                                sx={{
                                                    width: 85,
                                                    height: 8,
                                                    borderRadius: '7px'
                                                }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                variant="subtitle1"
                                                fontSize="0.75rem"
                                            >
                                                {level?.label}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </FormControl>
                        )}

                        {errors.submit && (
                            <Box sx={{ mt: 3 }}>
                                <FormHelperText error>
                                    {errors.submit}
                                </FormHelperText>
                            </Box>
                        )}

                        <Box sx={{ mt: 2 }}>
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    disabled={isSubmitting}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    S'inscrire
                                </Button>
                            </AnimateButton>
                        </Box>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default AWSCognitoRegister
