import { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import Loadable from 'ui-component/Loadable'
import PublicLayout from 'views/public/PublicLayout'
const LegalPage = Loadable(lazy(() => import('views/public/others/LegalPage')))
const TermsPage = Loadable(lazy(() => import('views/public/others/Terms')))
const SalesTermsPage = Loadable(lazy(() => import('views/public/others/SalesTerms')))
const PublicRoutes = {
    path: '/',
    element: (
        <PublicLayout withAppBar={true} />
    ),
    children: [
        {
            path: '/',
            element: <Navigate to="/dashboard" />
        },
        {
            path: '/legal',
            element: <LegalPage />
        },
        {
            path: '/terms',
            element: <TermsPage />
        },
        {
            path: '/general-sales-conditions',
            element: <SalesTermsPage />
        }
    ]
}

export default PublicRoutes
