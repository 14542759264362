import MenuIcon from '@mui/icons-material/Menu'
import {
    Box,
    Button,
    Container,
    Drawer,
    IconButton,
    Link,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    AppBar as MuiAppBar,
    Stack,
    Toolbar,
    Typography,
    useScrollTrigger
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { IconDashboard } from '@tabler/icons-react'
import { LOGIN_PATH } from 'config'
import useAuth from 'hooks/useAuth'
import PropTypes from 'prop-types'
import React from 'react'
import { useIntl } from 'react-intl'
import Logo from 'ui-component/Logo'
import Avatar from 'ui-component/extended/Avatar'

function ElevationScroll({ children, window }) {
    const theme = useTheme()
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window
    })
    const darkBorder =
        theme.palette.mode === 'dark'
            ? theme.palette.dark.dark
            : theme.palette.grey[200]

    return React.cloneElement(children, {
        elevation: trigger ? 2 : 0,
        style: {
            backgroundColor: theme.palette.background.default,
            borderBottom: trigger ? 'none' : '1px solid',
            borderColor: trigger ? '' : darkBorder,
            color: theme.palette.text.dark
        }
    })
}

ElevationScroll.propTypes = {
    children: PropTypes.node,
    window: PropTypes.object
}

// ==============================|| MINIMAL LAYOUT APP BAR ||============================== //

const Header = ({ ...others }) => {
    const [drawerToggle, setDrawerToggle] = React.useState(false)
    const theme = useTheme()

    const drawerToggler = (open) => (event) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return
        }
        setDrawerToggle(open)
    }

    const { isLoggedIn, getUserLetters } = useAuth()
    const intl = useIntl()

    return (
        <ElevationScroll {...others}>
            <MuiAppBar>
                <Container>
                    <Toolbar>
                        <Typography
                            component={Link}
                            sx={{ flexGrow: 1, textAlign: 'left' }}
                            href="/"
                        >
                            <Logo />
                        </Typography>
                        <Stack
                            direction="row"
                            sx={{ display: { xs: 'none', sm: 'block' } }}
                            spacing={2}
                        >
                            <Button
                                component={Link}
                                href="/dashboard"
                                target="_blank"
                                rel="noreferrer"
                                size="small"
                                variant="outlined"
                                color="primary"
                            >
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    gap={1}
                                >
                                    {isLoggedIn && (
                                        <Avatar
                                            sx={{
                                                ...theme.typography.smallAvatar,
                                                cursor: 'pointer',
                                                fontSize: '0.60rem'
                                            }}
                                            color="primary"
                                        >
                                            {getUserLetters()}
                                        </Avatar>
                                    )}
                                    Dashboard
                                </Stack>
                            </Button>
                        </Stack>
                        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                            <IconButton
                                color="inherit"
                                onClick={drawerToggler(true)}
                                size="large"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Drawer
                                anchor="top"
                                open={drawerToggle}
                                onClose={drawerToggler(false)}
                            >
                                {drawerToggle && (
                                    <Box
                                        sx={{ width: 'auto' }}
                                        role="presentation"
                                        onClick={drawerToggler(false)}
                                        onKeyDown={drawerToggler(false)}
                                    >
                                        <List>
                                            <Link
                                                style={{
                                                    textDecoration: 'none'
                                                }}
                                                href={LOGIN_PATH}
                                                target="_blank"
                                            >
                                                <ListItemButton component="a">
                                                    <ListItemIcon>
                                                        <IconDashboard />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        sx={{
                                                            textTransform:
                                                                'uppercase'
                                                        }}
                                                        primary={intl.formatMessage(
                                                            { id: 'Dashboard' }
                                                        )}
                                                    />
                                                </ListItemButton>
                                            </Link>
                                        </List>
                                    </Box>
                                )}
                            </Drawer>
                        </Box>
                    </Toolbar>
                </Container>
            </MuiAppBar>
        </ElevationScroll>
    )
}

export default Header
