import { Button, CardContent, CardMedia, Grid, Typography } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { gridSpacing } from 'store/constant'

import { LockTwoTone, RefreshOutlined } from '@mui/icons-material'
import imageDarkBackground from 'assets/images/maintenance/img-error-bg-dark.svg'
import imageBackground from 'assets/images/maintenance/img-error-bg.svg'
import imageBlue from 'assets/images/maintenance/img-error-blue.svg'
import imagePurple from 'assets/images/maintenance/img-error-purple.svg'
import imageText from 'assets/images/maintenance/img-error-text.svg'

const CardMediaWrapper = styled('div')({
    maxWidth: 720,
    margin: '0 auto',
    position: 'relative'
})

const ErrorWrapper = styled('div')({
    maxWidth: 350,
    margin: '0 auto',
    textAlign: 'center'
})

const CardMediaBlock = styled('img')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    animation: '3s bounce ease-in-out infinite'
})

const CardMediaBlue = styled('img')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    animation: '15s wings ease-in-out infinite'
})

const CardMediaPurple = styled('img')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    animation: '12s wings ease-in-out infinite'
})

// ==============================|| ERROR PAGE ||============================== //

const Error = ({ icon = null, message1 = null, message2 = null }) => {
    const theme = useTheme()

    return (
        <CardContent>
            <Grid container justifyContent="center" spacing={gridSpacing}>
                <Grid item xs={12}>
                    <CardMediaWrapper sx={{ textAlign: 'center' }}>
                        {icon ? (
                            <LockTwoTone
                                color={theme.palette.secondary.main}
                                sx={{ fontSize: 200, textAlign: 'center' }}
                            />
                        ) : (
                            <>
                                <CardMedia
                                    component="img"
                                    image={
                                        theme.palette.mode === 'dark'
                                            ? imageDarkBackground
                                            : imageBackground
                                    }
                                    title="Slider5 image"
                                />
                                <CardMediaBlock
                                    src={imageText}
                                    title="Slider 1 image"
                                />
                                <CardMediaBlue
                                    src={imageBlue}
                                    title="Slider 2 image"
                                />
                                <CardMediaPurple
                                    src={imagePurple}
                                    title="Slider 3 image"
                                />
                            </>
                        )}
                    </CardMediaWrapper>
                </Grid>
                <Grid item xs={12}>
                    <ErrorWrapper>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                {message1 ? (
                                    <Typography variant="h1">
                                        {message1}
                                    </Typography>
                                ) : (
                                    <Typography variant="h1">
                                        Contenu indisponible
                                    </Typography>
                                )}
                            </Grid>

                            {message2 && (
                                <Grid item xs={12}>
                                    <Typography variant="h3">
                                        {message2}
                                    </Typography>
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    size="large"
                                    fullWidth
                                >
                                    <RefreshOutlined
                                        sx={{
                                            fontSize: '1.3rem',
                                            mr: 0.75
                                        }}
                                    />{' '}
                                    Rafraichir
                                </Button>
                            </Grid>
                        </Grid>
                    </ErrorWrapper>
                </Grid>
            </Grid>
        </CardContent>
    )
}

export default Error
