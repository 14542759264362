import { Download } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import axios from 'axios'
import FileSaver from 'file-saver'
import React from 'react'

export default function DownloadButton({
    url,
    name,
    fileName = null,
    variant = 'contained',
    sx
}) {
    const [isDownloading, setIsDownloading] = React.useState(false)
    const handleDownload = (url) => {
        setIsDownloading(true)
        return axios.get(url, { responseType: 'blob' }).then((res) => {
            setIsDownloading(false)
            FileSaver.saveAs(res.data, fileName)
        })
    }

    return (
        <LoadingButton
            onClick={() => handleDownload(url)}
            loading={isDownloading}
            loadingPosition="end"
            endIcon={<Download />}
            variant={variant}
            sx={sx}
        >
            {name}
        </LoadingButton>
    )
}
