import {
    AppBar,
    Box,
    Container,
    CssBaseline,
    Toolbar,
    useMediaQuery
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { IconChevronRight } from '@tabler/icons-react'
import { Crisp } from 'crisp-sdk-web'
import useAuth from 'hooks/useAuth'
import useConfig from 'hooks/useConfig'
import navigation from 'menu-items'
import React, { useMemo } from 'react'
import { Outlet } from 'react-router-dom'
import { useDispatch, useSelector } from 'store'
import { drawerWidth } from 'store/constant'
import { openDrawer } from 'store/slices/menu'
import Breadcrumbs from 'ui-component/extended/Breadcrumbs'
import SurveyLayout from 'views/surveys/SurveyLayout'
import Header from './Header'
import Sidebar from './Sidebar'

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        ...theme.typography.mainContent,
        ...(!open && {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.shorter
            }),
            [theme.breakpoints.up('md')]: {
                marginLeft: -(drawerWidth - 20),
                width: `calc(100% - ${drawerWidth}px)`
            },
            [theme.breakpoints.down('md')]: {
                marginLeft: '20px',
                width: `calc(100% - ${drawerWidth}px)`,
                padding: '16px'
            },
            [theme.breakpoints.down('sm')]: {
                marginLeft: '10px',
                width: `calc(100% - ${drawerWidth}px)`,
                padding: '16px',
                marginRight: '10px'
            }
        }),
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.shorter
            }),
            marginLeft: 0,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            width: `calc(100% - ${drawerWidth}px)`,
            [theme.breakpoints.down('md')]: {
                marginLeft: '20px'
            },
            [theme.breakpoints.down('sm')]: {
                marginLeft: '10px'
            }
        })
    })
)

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = (props) => {
    const theme = useTheme()
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'))

    const dispatch = useDispatch()
    const { drawerOpen } = useSelector((state) => state.menu)
    const { container } = useConfig()
    const { user } = useAuth()
    React.useEffect(() => {
        dispatch(openDrawer(true))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd])

    React.useEffect(() => {
        Crisp.configure('4d6b4077-f442-426a-af68-d4cb549e54c7')
        if (user) {
            Crisp.user.setEmail(user.email)
            Crisp.user.setNickname(user.name)
        }
    }, [user])

    React.useEffect(() => {
        //  Set title dashboard
        document.title = 'SpokingPolls Dashboard'
    }, [])

    const header = useMemo(
        () => (
            <Toolbar>
                <Header />
            </Toolbar>
        ),
        []
    )

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default,
                    transition: drawerOpen
                        ? theme.transitions.create('width')
                        : 'none'
                }}
            >
                {header}
            </AppBar>

            <Sidebar />
            <Main theme={theme} open={drawerOpen}>
                {container && (
                    <Container maxWidth="lg">
                        <Breadcrumbs
                            separator={IconChevronRight}
                            navigation={navigation}
                            icon
                            title
                            rightAlign
                        />
                        {props.layout ? (
                            <SurveyLayout>
                                <Outlet />
                            </SurveyLayout>
                        ) : (
                            <Outlet />
                        )}
                    </Container>
                )}
                {!container && (
                    <>
                        <Breadcrumbs
                            separator={IconChevronRight}
                            navigation={navigation}
                            icon
                            title
                            rightAlign
                        />
                        {props.layout ? (
                            <SurveyLayout>
                                <Outlet />
                            </SurveyLayout>
                        ) : (
                            <Outlet />
                        )}
                    </>
                )}
            </Main>
        </Box>
    )
}

export default MainLayout
