import { Chip as MuiChip } from '@mui/material'
import {
    IconLockOpen,
    IconLockOpenOff,
    IconWritingSign
} from '@tabler/icons-react'

const SURVEY_STATUS = {
    OPENED: {
        text: 'OUVERT',
        chip: (
            <MuiChip
                size="small"
                color="success"
                variant="contained"
                icon={<IconLockOpen size={16} />}
                title={'Ouvert'}
                label="OUVERT"
            />
        )
    },
    DRAFT: {
        text: 'DRAFT',
        chip: (
            <MuiChip
                size="small"
                color="warning"
                icon={<IconWritingSign size={16} />}
                title={'Brouillon'}
                label={'BROUILLON'}
            />
        )
    },
    CLOSED: {
        text: 'FERMÉ',
        chip: (
            <MuiChip
                size="small"
                color="error"
                icon={<IconLockOpenOff size={16} />}
                title={'Fermé'}
                label={'FERMÉ'}
            />
        )
    }
}

const SURVEY_QUESTION_TYPE = {
    TEXT: 'Textuelle',
    SINGLE_CHOICE: 'Choix unique',
    MULTIPLE_CHOICE: 'Choix multiples',
    DISPLAYED_TEXT: 'Bloc de texte sans réponse'
}

export const surveyQuestionTypeText = (type) => {
    if (Object.keys(SURVEY_QUESTION_TYPE).includes(type)) {
        return SURVEY_QUESTION_TYPE[type]
    }
    return ''
}

export const surveyStatusText = (status) => {
    if (Object.keys(SURVEY_STATUS).includes(status)) {
        return SURVEY_STATUS[status].text
    }
    return ''
}

export const surveyStatusChip = (status) => {
    if (Object.keys(SURVEY_STATUS).includes(status)) {
        return SURVEY_STATUS[status].chip
    }
    return ''
}

export function stringToColor(string) {
    let hash = 0
    let i

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash)
    }

    let color = '#'

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff
        color += `00${value.toString(16)}`.slice(-2)
    }
    /* eslint-enable no-bitwise */
    return color
}

export const stringAvatar = (name, sx = null) => {
    return {
        sx: {
            bgcolor: stringToColor(name),
            color: 'white',
            ...sx
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
    }
}
