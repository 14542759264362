// material-ui
import { Grid, Typography } from '@mui/material'

import Chart from 'react-apexcharts'
import { gridSpacing } from 'store/constant'
import MainCard from 'ui-component/cards/MainCard'

const BarChart = ({
    isLoading,
    xAxisSeries,
    yAxisSeries,
    title,
    yAxisTitle
}) => {
    // translate _OTHER_ AND _NONE_ to french before displaying charts
    const xAxisSeriesSanitized = xAxisSeries.map((x) => {
        if (x === '_OTHER_') {
            return 'Autre'
        } else if (x === '_NONE_') {
            return 'Aucune'
        } else {
            return x
        }
    })

    const options = {
        chart: {
            height: 300,
            type: 'bar',
            toolbar: {
                show: true
            }
        },

        plotOptions: {
            bar: {
                distributed: true
            }
        },
        dataLabels: {
            enabled: false
        },
        toolbar: {
            show: true
        },
        legend: {
            show: false
        },
        xaxis: {
            decimalsInFloat: 0,
            categories: xAxisSeriesSanitized,
            labels: {
                style: {
                    fontSize: '12px'
                }
            }
        },
        yaxis: {
            decimalsInFloat: 0,
            // forceNiceScale: true,
            labels: {
                formatter: (val) => {
                    return val.toFixed(0)
                }
            }
        }
    }

    const series = [
        {
            name: yAxisTitle,
            data: yAxisSeries
        }
    ]

    return (
        <>
            {isLoading ? (
                <>Création du diagramme en cours...</>
            ) : (
                <MainCard>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Typography variant="h4" color="secondary">
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Chart
                                options={options}
                                series={series}
                                type="bar"
                                height={250}
                            />
                        </Grid>
                    </Grid>
                </MainCard>
            )}
        </>
    )
}

export default BarChart
