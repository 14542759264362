import { lazy } from 'react'
import Loadable from 'ui-component/Loadable'
import PublicLayout from 'views/public/PublicLayout'
import Error from 'views/ui-elements/Error'

const SurveyPublicPage = Loadable(
    lazy(() => import('views/public/PublicSurveyPage'))
)


const SurveyPublicRoutes = {
    path: '/',
    element: <PublicLayout withAppBar={false} />,
    children: [
        {
            path: '/surveys/:id/:collectorId',
            element: <SurveyPublicPage />
        },
        {
            path: '*',
            element: <Error />
        }
    ]
}

export default SurveyPublicRoutes
