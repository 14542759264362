import { combineReducers } from 'redux';
import snackbarReducer from './slices/snackbar';
import menuReducer from './slices/menu';
import surveyReducer from './slices/survey';
// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: snackbarReducer,
    survey: surveyReducer,
    menu: menuReducer,
});

export default reducer;
