import { Grid, Typography } from '@mui/material'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import MainCard from 'ui-component/cards/MainCard'
import DownloadButton from 'views/ui-elements/DownloadButton'
import Error from 'views/ui-elements/Error'
import QuestionResult from '../ui-elements/surveys/QuestionResult'

const SurveyResultPage = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [result, setResult] = useState(null)
    const [error, setError] = useState(null)

    const { id } = useParams()

    useEffect(() => {
        setIsLoading(true)
        setError(false)
        axios
            .get(`/surveys/${id}/results-by-question`)
            .then((res) => {
                setResult(res.data)
                setIsLoading(false)
            })
            .catch((err) => {
                setError(true)
                setIsLoading(false)
            })
    }, [id])

    if (isLoading) {
        return 'Chargement en cours'
    }

    if (error || !result) {
        return <Error />
    }

    return (
        <>
            <MainCard
                title={
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                    >
                        <Grid item>
                            <Typography variant="h3">
                                Resultats du sondage
                            </Typography>
                        </Grid>
                        <Grid item>
                            <DownloadButton
                                fileName="resultats.csv"
                                url={`/surveys/${id}/responders-in-csv`}
                                name="EXPORTER LES RESULTATS EN CSV"
                            />
                        </Grid>
                    </Grid>
                }
                content={false}
            ></MainCard>
            {result.map((r) => (
                <QuestionResult result={r} key={`qr-${r.questionId}`} />
            ))}
        </>
    )
}

export default SurveyResultPage
