import { Chip, Grid, Typography } from '@mui/material'
import MainCard from 'ui-component/cards/MainCard'
import { surveyQuestionTypeText } from 'views/surveys/survey-utils'
import SurveyResultChartBar from '../../../ui-component/BarChart'

const QuestionResult = ({ result }) => {
    let sanitizedStats = null
    if (result && result.stats) {
        sanitizedStats = result.stats
        if (
            result.question.type !== 'TEXT' &&
            !result.question.hasOtherAccepted
        ) {
            // Remove key _OTHER_ in sanitizedStats and affects it back to sanitizedStats
            sanitizedStats = Object.fromEntries(
                Object.entries(sanitizedStats).filter(([key, value]) => {
                    return key !== '_OTHER_'
                })
            )
        }

        if (
            result.question.type !== 'TEXT' &&
            !result.question.hasNoneAccepted
        ) {
            // Remove key _NONE_ in sanitizedStats and affects it back to sanitizedStats
            sanitizedStats = Object.fromEntries(
                Object.entries(sanitizedStats).filter(([key, value]) => {
                    return key !== '_NONE_'
                })
            )
        }
    }

    if (sanitizedStats) {
        sanitizedStats = Object.fromEntries(
            Object.entries(sanitizedStats).sort(([, a], [, b]) => b - a)
        )
    }
    return (
        <MainCard
            title={
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        <Typography variant="h4" display="inline">
                            {result.question.title}
                        </Typography>
                        <Typography variant="caption" display="inline">
                            &nbsp; (
                            {surveyQuestionTypeText(result.question.type)})
                        </Typography>
                    </Grid>
                    <Grid item xs={6} textAlign="right">
                        <Chip
                            label={`${result.question.nbResponses} répondant(s)`}
                            color="warning"
                            size="small"
                        />
                    </Grid>
                </Grid>
            }
            content={true}
            sx={{ mt: 2 }}
        >
            {result.question.type === 'TEXT' ? (
                <Typography variant="caption">
                    Réponse par texte libre. Aucune analyse possible.
                </Typography>
            ) : sanitizedStats ? (
                <SurveyResultChartBar
                    yAxisTitle="Nombre de réponses"
                    yAxisSeries={Object.values(sanitizedStats)}
                    xAxisSeries={Object.keys(sanitizedStats)}
                />
            ) : (
                <Typography variant="caption">
                    Encore aucun répondant à cette question.
                </Typography>
            )}
        </MainCard>
    )
}

export default QuestionResult
