import {
    HelpCenter,
    ImportContactsOutlined,
    ImportContactsSharp,
    TwoKOutlined
} from '@mui/icons-material'
import {
    Alert,
    Divider,
    Drawer,
    Fab,
    Grid,
    Stack,
    Typography
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import Accordion from 'ui-component/extended/Accordion'

const useStyles = makeStyles({
    drawer: {
        width: 600,
        flexShrink: 0
    }
})

const useStylesHelperFab = makeStyles({
    fab: {
        position: 'fixed',
        bottom: '20px',
        right: '20px'
    }
})

export function SurveySideHelper() {
    const classes = useStylesHelperFab()
    const [drawerOpen, setDrawerOpen] = React.useState(false)

    const handleOpenDrawer = () => {
        setDrawerOpen(true)
    }

    const handleCloseDrawer = () => {
        setDrawerOpen(false)
    }

    return (
        <div>
            <Fab
                className={classes.fab}
                color="primary"
                onClick={handleOpenDrawer}
            >
                <HelpCenter />
            </Fab>
            <SideHelper open={drawerOpen} onClose={handleCloseDrawer} />
        </div>
    )
}

const customContentData = [
    {
        id: 'basic1',
        defaultExpand: true,
        title: (
            <Stack direction="row" gap={2}>
                <ImportContactsOutlined />
                <Typography variant="subtitle1" color="primary">
                    Créer un sondage
                </Typography>
            </Stack>
        ),
        content: (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">Structurer son questionnaire</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">Bien choisir le type de question</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">Estimer la durée du questionnaire</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">Personnalisation du design</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            </Grid>
        )
    },
    {
        id: 'basic2',
        title: (
            <Stack direction="row" gap={2}>
                <TwoKOutlined />
                <Typography variant="subtitle1" color="error">
                    Diffuser un sondage
                </Typography>
            </Stack>
        ),
        content:
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">Choix de l'echantillon cible</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">RGPD, Respect de la confidentialité</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">Utilisation des liens génériques</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">Bonnes pratiques de diffusion</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                 <Grid item xs={12}>
                    <Typography variant="subtitle1">Animation et incitation</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            </Grid>
            
    },
    {
        id: 'basic3',
        title: (
            <Stack direction="row" gap={2}>
                <ImportContactsSharp />
                <Typography variant="subtitle1" color="secondary">
                    Analyser les résultats
                </Typography>
            </Stack>
        ),
        content:
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">Utilisation du tableau de bord</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">Suivi des réponses en temps réel</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">Analyse de la qualité des réponses</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">Quelles analyses et quels graphiques</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">Interprétation des résultats</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">Analyse par cible</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">Comment exploiter et analyser les verbatims des questions ouvertes</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            </Grid>
    }
]

export function SideHelper({ open, onClose }) {
    const classes = useStyles()

    return (
        <div>
            <Drawer
                anchor="right"
                open={open}
                PaperProps={{
                    sx: { width: '400px' }
                }}
                className={classes.drawer}
                onClose={onClose}
            >
                <Grid container spacing={2} p={1}>
                    <Grid item xs={12} textAlign="center">
                        <Alert severity="info">
                            Besoin d'aide ? Vous pouvez trouver ci-dessus des
                            rubriques pour vous aider.
                        </Alert>
                    </Grid>
                    <Grid item xs={12}>
                        <Accordion data={customContentData} />
                    </Grid>
                </Grid>
            </Drawer>
        </div>
    )
}
