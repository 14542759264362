// material-ui
import { Alert, Button, Fade, Grow, IconButton, Slide } from '@mui/material'
import MuiSnackbar from '@mui/material/Snackbar'

// assets
import CloseIcon from '@mui/icons-material/Close'

import { Error } from '@mui/icons-material'
import { useDispatch, useSelector } from 'store'
import { closeSnackbar } from 'store/slices/snackbar'

// animation function
function TransitionSlideLeft(props) {
    return <Slide {...props} direction="left" />
}

function TransitionSlideUp(props) {
    return <Slide {...props} direction="up" />
}

function TransitionSlideRight(props) {
    return <Slide {...props} direction="right" />
}

function TransitionSlideDown(props) {
    return <Slide {...props} direction="down" />
}

function GrowTransition(props) {
    return <Grow {...props} />
}

// animation options
const animation = {
    SlideLeft: TransitionSlideLeft,
    SlideUp: TransitionSlideUp,
    SlideRight: TransitionSlideRight,
    SlideDown: TransitionSlideDown,
    Grow: GrowTransition,
    Fade
}

// ==============================|| SNACKBAR ||============================== //

const Snackbar = () => {
    const dispatch = useDispatch()
    const snackbar = useSelector((state) => state.snackbar)
    const { actionButton, alert, close, message, open, transition, variant } =
        snackbar

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        dispatch(closeSnackbar())
    }

    return (
        <>
            {/* default snackbar */}
            {variant === 'default' && (
                <MuiSnackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message={message}
                    TransitionComponent={animation[transition]}
                    action={
                        <>
                            <Button
                                color="secondary"
                                size="small"
                                onClick={handleClose}
                            >
                                OK
                            </Button>
                            <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={handleClose}
                                sx={{ mt: 0.25 }}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </>
                    }
                />
            )}

            {/* alert snackbar */}
            {variant === 'alert' && (
                <MuiSnackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    icon={false}
                    TransitionComponent={animation[transition]}
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                    <Alert
                        variant={alert.variant}
                        icon={<Error />}
                        color={alert.color}
                        action={
                            <>
                                {actionButton !== false && (
                                    <Button
                                        size="small"
                                        onClick={handleClose}
                                        sx={{ color: 'background.paper' }}
                                    >
                                        ANNULER
                                    </Button>
                                )}
                                {close !== false && (
                                    <IconButton
                                        sx={{ color: 'background.paper' }}
                                        size="small"
                                        aria-label="close"
                                        onClick={handleClose}
                                    >
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                )}
                            </>
                        }
                        sx={{
                            ...(alert.variant === 'outlined' && {
                                bgcolor: 'background.paper'
                            })
                        }}
                    >
                        {message}
                    </Alert>
                </MuiSnackbar>
            )}
        </>
    )
}

export default Snackbar
