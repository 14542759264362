// material-ui
import { useTheme } from '@emotion/react'
import HomeIcon from '@mui/icons-material/Home'
import {
    Box,
    Card,
    Grid,
    Breadcrumbs as MuiBreadcrumbs,
    Stack,
    Typography
} from '@mui/material'
import Link from '@mui/material/Link'
import { IconChevronRight } from '@tabler/icons-react'
import { useCallback, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useParams } from 'react-router-dom'
import { getSurvey, getSurveyStats } from 'store/slices/survey'
import { fromIsoDateToLocaleDate } from 'utils/dates'
import Error from 'views/ui-elements/Error'
import SurveyCustomLogo from 'views/ui-elements/SurveyCustomLogo'
import { SurveySideHelper } from 'views/ui-elements/surveys/SurveySideHelper'

const SurveyLayout = () => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { survey, isLoadingSurvey } = useSelector((state) => state.survey)
    const subMenuOpen = useSelector((state) => state.menu.openItem)
    const { id } = useParams()
    const [, setIntervalId] = useState()

    const refreshStats = useCallback(() => {
        dispatch(getSurveyStats(id, true))
    }, [dispatch, id])

    useEffect(() => {
        refreshStats()
        const intervalId = setInterval(refreshStats, 10 * 1000)
        setIntervalId(intervalId)
        return () => clearInterval(intervalId)
    }, [refreshStats])

    useEffect(() => {
        dispatch(getSurvey(id, false))
    }, [dispatch, id])

    const linkSX = {
        display: 'flex',
        color: 'grey.900',
        textDecoration: 'none',
        alignContent: 'center',
        alignItems: 'center'
    }

    const iconStyle = {
        marginRight: theme.spacing(0.75),
        marginTop: `-${theme.spacing(0.25)}`,
        width: '1rem',
        height: '1rem',
        color: theme.palette.secondary.main
    }

    if (isLoadingSurvey) {
        return <>{/* <Loader /> */}</>
    }
    if (!survey) {
        return <Error />
    }

    return (
        <>
            <Card
                sx={{
                    marginBottom: 1,
                    border: '1px solid',
                    borderColor:
                        theme.palette.mode === 'dark'
                            ? theme.palette.background.default
                            : theme.palette.primary[200] + 75,
                    background: theme.palette.background.default
                }}
            >
                <Box sx={{ p: 2 }}>
                    <Grid
                        container
                        direction={'row'}
                        alignItems={'center'}
                        spacing={1}
                    >
                        <Grid item sx={{ mr: 2 }}>
                            <SurveyCustomLogo
                                height={40}
                                surveyCustomizationLogo={
                                    survey.customization.surveyLogo
                                }
                            />
                        </Grid>
                        <Grid item>
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                            >
                                <Typography
                                    variant="h3"
                                    sx={{ fontWeight: 500 }}
                                >
                                    {survey && survey.name}
                                </Typography>
                            </Stack>
                            <Typography
                                variant="caption"
                                sx={{ fontWeight: 500 }}
                            >
                                Dernière modification le{' '}
                                {survey &&
                                    fromIsoDateToLocaleDate(survey.updatedAt)}
                            </Typography>
                        </Grid>
                        <Grid item xs zeroMinWidth />
                        <Grid textAlign="right">
                            <MuiBreadcrumbs
                                sx={{
                                    '& .MuiBreadcrumbs-separator': {
                                        width: 16,
                                        ml: 1.25,
                                        mr: 1.25,
                                        textAlign: 'center'
                                    }
                                }}
                                aria-label="breadcrumb"
                                maxItems={5}
                                separator={
                                    <IconChevronRight
                                        stroke={1.5}
                                        size="1rem"
                                    />
                                }
                            >
                                <Typography
                                    component={Link}
                                    to="/"
                                    color="inherit"
                                    variant="subtitle1"
                                    sx={linkSX}
                                >
                                    <HomeIcon sx={iconStyle} />
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        display: 'flex',
                                        textDecoration: 'none',
                                        alignContent: 'center',
                                        alignItems: 'center',
                                        color: 'grey.500'
                                    }}
                                >
                                    Mes sondages
                                </Typography>
                                {subMenuOpen && subMenuOpen.length === 1 && (
                                    <Typography
                                        variant="subtitle1"
                                        sx={{
                                            display: 'flex',
                                            textDecoration: 'none',
                                            alignContent: 'center',
                                            alignItems: 'center',
                                            color: 'grey.500'
                                        }}
                                    >
                                        <FormattedMessage
                                            id={`${subMenuOpen[0]}`}
                                        />
                                    </Typography>
                                )}
                            </MuiBreadcrumbs>
                        </Grid>
                    </Grid>
                </Box>
            </Card>
            <SurveySideHelper />
            <Outlet />
        </>
    )
}

export default SurveyLayout
