import {
    Card,
    CardContent,
    Divider,
    Grid,
    LinearProgress,
    Stack,
    Typography,
    linearProgressClasses
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import useAuth from 'hooks/useAuth'
import PropTypes from 'prop-types'
import { memo } from 'react'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 30,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.dark.light : '#fff'
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.secondary.dark
    }
}))

const CardStyle = styled(Card)(({ theme }) => ({
    background:
        theme.palette.mode === 'dark'
            ? theme.palette.dark.main
            : theme.palette.primary.light,
    marginBottom: '22px',
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: '157px',
        height: '157px',
        background:
            theme.palette.mode === 'dark'
                ? theme.palette.dark.dark
                : theme.palette.primary[200],
        borderRadius: '50%',
        top: '-105px',
        right: '-96px'
    }
}))

// ==============================|| PROGRESS BAR WITH LABEL ||============================== //

function LinearProgressWithLabel({ value, limitValue, label, ...others }) {
    const theme = useTheme()
    const percent = Math.min(Math.round((value / limitValue) * 100), 100)

    return (
        <Grid container direction="column" spacing={1} sx={{ mt: 1.5 }}>
            <Grid item>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography
                            variant="h6"
                            sx={{
                                color:
                                    theme.palette.mode === 'dark'
                                        ? theme.palette.dark.light
                                        : theme.palette.primary[800]
                            }}
                        >
                            {label}
                            <Typography variant="caption">
                                &nbsp; &nbsp; ({value} / {limitValue})
                            </Typography>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" color="inherit">{`${Math.round(
                            percent
                        )}%`}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <BorderLinearProgress
                    variant="determinate"
                    color="error"
                    value={percent}
                    {...others}
                />
            </Grid>
        </Grid>
    )
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number
}

// ==============================|| SIDEBAR - MENU CARD ||============================== //

const MenuCard = () => {
    const { user } = useAuth()
    return (
        <CardStyle>
            <CardContent sx={{ p: 2 }}>
                <Stack direction="column" gap={1}>
                    ACCES DASHBOARD
                    <Divider />
                    <Typography variant="caption" color="inherit">
                        {user && user.email}
                    </Typography>
                </Stack>
            </CardContent>
        </CardStyle>
    )
}

export default memo(MenuCard)
