import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext'
import NavigationScroll from 'layout/NavigationScroll'
import Routes from 'routes'
import ThemeCustomization from 'themes'
import Locales from 'ui-component/Locales'
import Snackbar from 'ui-component/extended/Snackbar'

const App = () => {
    return (
        <ThemeCustomization>
            <Locales>
                <NavigationScroll>
                    <AuthProvider>
                        <>
                            <Routes />
                            <Snackbar />
                        </>
                    </AuthProvider>
                </NavigationScroll>
            </Locales>
        </ThemeCustomization>
    )
}
export default App
