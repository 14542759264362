import * as Sentry from '@sentry/react'

import App from 'App'
import 'assets/scss/style.scss'
import axios from 'axios'
import { BASE_PATH } from 'config'
import { ConfigProvider } from 'contexts/ConfigContext'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import {
    BrowserRouter,
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType
} from 'react-router-dom'
import reportWebVitals from 'reportWebVitals'
import * as serviceWorker from 'serviceWorker'
import { openSnackbarWithError } from 'utils/errors'
import { store } from './store'
if (
    process.env.REACT_APP_NODE_ENV === 'production' ||
    process.env.REACT_APP_NODE_ENV === 'staging'
) {
    console.log('Sentry.init', process.env.REACT_APP_SENTRY_DSN)
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes
                )
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            }),
            new Sentry.Replay()
        ],
        tracePropagationTargets: [
            'localhost',
            'https://spokingpolls-api.herokuapp.com/'
        ],

        environment: process.env.REACT_APP_NODE_ENV,
        // Performance Monitoring
        tracesSampleRate:
            process.env.REACT_APP_NODE_ENV === 'staging' ? 1 : 0.01, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate:
            process.env.REACT_APP_NODE_ENV === 'staging' ? 1 : 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
}

axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL

// add axios interceptors to log error
axios.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        console.error('error with axios', error)
        openSnackbarWithError(error, store.dispatch)
        return Promise.reject(error)
    }
)

// ==============================|| REACT DOM RENDER  ||============================== //
ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider>
            <BrowserRouter basename={BASE_PATH}>
                <App />
            </BrowserRouter>
        </ConfigProvider>
    </Provider>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
