// material-ui
import { Link, Stack, Typography } from '@mui/material'

// ==============================|| FOOTER - AUTHENTICATION 2 & 3 ||============================== //

const AuthFooter = () => (
    <Stack direction="row" textAlign={'center'} justifyContent="space-around">
        <Typography
            variant="subtitle2"
            component={Link}
            href="https://spokingpolls.com"
            target="_blank"
            underline="hover"
        >
            &copy; Spoking polls
        </Typography>
        <Typography variant="subtitle2" underline="hover">
            v{process.env.REACT_APP_VERSION}
        </Typography>
    </Stack>
)

export default AuthFooter
