import { useRoutes } from 'react-router-dom'

// routes
import LoginRoutes from './LoginRoutes'
import MainRoutes from './MainRoutes'
import PublicRoutes from './PublicRoutes'
import SurveyPublicRoutes from './SurveyPublicRoutes'
import SurveyRoutes from './SurveyRoutes'
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([
        PublicRoutes,
        MainRoutes,
        LoginRoutes,
        SurveyRoutes,
        SurveyPublicRoutes
    ])
}
