import { Component } from 'react'
import GlobalError from 'views/ui-elements/GlobalError'

class PublicErrorBoundary extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hasError: false
        }
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ hasError: true })
        localStorage.clear()
        console.error(error, errorInfo)
    }

    render() {
        if (this.state.hasError) {
            return (
                <GlobalError
                    message1={'Nous sommes désolé ...'}
                    message2={
                        "Une erreur inconnue s'est produite. Peut-être devriez-vous rafraichir la page ?"
                    }
                />
            )
        }

        return this.props.children
    }
}

export default PublicErrorBoundary
